import React, { useRef, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Scans.css";

export default function Scans(props) {
  const [scan, setScan] = useState(null);
  const [accessId, setAccessId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountkey, setAccountkey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadScan() {
      return API.get("scans", `/scans/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const scan = await loadScan();
        const { accessId, accountId, accountkey } = scan;

        setAccessId(accessId);
        setAccountId(accountId);
        setAccountkey(accountkey);
        setScan(scan);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
    // TODO: fix when all items in DB have accountkey
    // return account.length > 0 && accountkey.length > 0;
    // return account.length > 0 && accountkey.length > 0;
    return accessId.length > 0;
  }

  function saveScan(scan) {
    return API.put("scans", `/scans/${props.match.params.id}`, {
      body: scan,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveScan({
        accessId,
        accountkey,
      });
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function deleteScan() {
    return API.del("scans", `/scans/${props.match.params.id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this scan?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteScan();
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }
  }

  return (
    <div className="Scans">
      {scan && (
        <>
          <>
            <div>
              <h1>AWS Account</h1>
              <p>{accountId}</p>
              <h1>Results</h1>
              {Array.isArray(scan["ec2Result"]) &&
                scan["ec2Result"].map(function (region) {
                  if (
                    Array.isArray(region.Instances) &&
                    region.Instances.length !== 0
                  ) {
                    return (
                      <div>
                        <div>
                          <h1>{region.Region}</h1>
                          <h2>
                            Instance ID - Public IP - Security Groups & TCP Port
                          </h2>
                        </div>
                        {region.Instances.map(function (instance) {
                          return (
                            <>
                              {instance.InstanceId} : {instance.PublicIpAddress}
                              {instance.GroupsAndPorts.map((sg) => {
                                return (
                                  <>
                                    {" "}
                                    {sg.GroupId} TCP: {sg.Ports.tcp} UDP:{" "}
                                    {sg.Ports.udp} ICMP: {sg.Ports.icmp} All:{" "}
                                    {sg.Ports.all}
                                  </>
                                );
                              })}
                              <br></br>
                            </>
                          );
                        })}
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
            </div>
            <br></br>

            <LoaderButton
              block
              type="submit"
              bsSize="large"
              bsStyle="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Save
            </LoaderButton>
            <LoaderButton
              block
              bsSize="large"
              bsStyle="danger"
              onClick={handleDelete}
              isLoading={isDeleting}
            >
              Delete
            </LoaderButton>
          </>
        </>
      )}
    </div>
  );
}
