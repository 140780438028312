export default {
MAX_ATTACHMENT_SIZE: 5000000,
STRIPE_KEY: "pk_test_SAMKyZ3k86MlDSvCnA5PHZsa00Kmy5tu4i",
  s3: {
    REGION: "us-east-1",
    BUCKET: "cloudscan-api-dev-attachmentsbucket-1fogkh5hzxl0z"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://tz3oz6le65.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_alEoIWvle",
    APP_CLIENT_ID: "5fd6v127iei4o4792eqvvrjuge",
    IDENTITY_POOL_ID: "us-east-1:a72c2c81-b311-4777-95bb-5ab5ec5784de"
  }
};
