import React, { useState, useEffect } from "react";
import "./Home.css";
import { API } from "aws-amplify";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function Home(props) {
  const [scans, setScans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const scans = await loadScans();
        setScans(scans);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadScans() {
    return API.get("scans", "/scans");
  }

  function renderScansList(scans) {
    return [{}].concat(scans).map((scan, i) =>
      i !== 0 ? (
        <LinkContainer key={scan.scanId} to={`/scans/${scan.scanId}`}>
          <ListGroupItem header={scan.accessId.trim().split("\n")[0]}>
            {"Created: " + new Date(scan.createdAt).toLocaleString()}
          </ListGroupItem>
        </LinkContainer>
      ) : (
        <LinkContainer key="new" to="/scans/new">
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Create a new scan
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Cloudscan</h1>
        <p>Find all your cloud assets exposed to the public. </p>
      </div>
    );
  }

  function renderScans() {
    return (
      <div className="scans">
        <PageHeader>Your Scans</PageHeader>
        <ListGroup>{!isLoading && renderScansList(scans)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ? renderScans() : renderLander()}
    </div>
  );
}
