import React, { useRef, useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./NewScan.css";
import { API } from "aws-amplify";

export default function NewScan(props) {
  const [account, setAccount] = useState("");
  const [accountkey, setAccountkey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return account.length > 0 && accountkey.length > 2;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // await createScan({ account, accountkey });
      await pushScan({ account, accountkey });
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createScan(scan) {
    return API.post("scans", "/scans", {
      body: scan
    });
  }

  function pushScan(scan) {
    return API.post("scans", "/pushscan", {
      body: scan
    });
  }

  return (
    <div className="NewScan">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="account">
          <ControlLabel>Account ID</ControlLabel>
          <FormControl
            value={account}
            type="text"
            onChange={e => setAccount(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="accountkey">
          <ControlLabel>Account Key</ControlLabel>
          <FormControl
            value={accountkey}
            type="text"
            onChange={e => setAccountkey(e.target.value)}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Submit scan
        </LoaderButton>
      </form>
    </div>
  );
}
